import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      placeholder: "Sartu EHko edozein herri",
      guess: "Asmatu",
      share: "Zabaldu",
      showOnGoogleMaps: "👀 OpenStreetMaps-en",
      welldone: "Ondo!",
      unknownCountry: "Herri ezezaguna!",
      copy: "Emaitzak arbelean kopiatuta!",
      showCountry: "🗺️ Erakutsi mapan!",
      cancelRotation: "🌀 Ezeztatu errotazioa",
      settings: {
        title: "Aukerak",
        distanceUnit: "Distantzia unitatea",
        theme: "Gaia",
        difficultyModifiers: "Zailtasun aldagaiak",
        startingNextDay: "Bihar egingo dira aldaketak!",
        noImageMode: "Ezkutatu herriaren irudia",
        rotationMode: "Errotatu ausaz herriaren irudia.",
      },
      stats: {
        title: "Estatistikak",
        played: "Jokatuta",
        win: "Irabaziak %",
        currentStreak: "Uneko seriea",
        maxStreak: "Serie Max",
        averageBestDistance: "Batazbesteko distantzia onena",
        guessDistribution: "Asmatze banaketa:",
      },
      buyMeACoffee: "Erosi iezadazu kafetxo bat ☕!",
    },
  },
  eu: {
    translation: {
      placeholder: "Sartu EHko edozein herri",
      guess: "Asmatu",
      share: "Zabaldu",
      showOnGoogleMaps: "👀 OpenStreetMaps-en",
      welldone: "Ondo!",
      unknownCountry: "Herri ezezaguna!",
      copy: "Emaitzak arbelean kopiatuta!",
      showCountry: "🗺️ Erakutsi mapan!",
      cancelRotation: "🌀 Ezeztatu errotazioa",
      settings: {
        title: "Aukerak",
        distanceUnit: "Distantzia unitatea",
        theme: "Gaia",
        difficultyModifiers: "Zailtasun aldagaiak",
        startingNextDay: "Bihar egingo dira aldaketak!",
        noImageMode: "Ezkutatu herriaren irudia",
        rotationMode: "Errotatu ausaz herriaren irudia.",
      },
      stats: {
        title: "Estatistikak",
        played: "Jokatuta",
        win: "Irabaziak %",
        currentStreak: "Uneko seriea",
        maxStreak: "Serie Max",
        averageBestDistance: "Batazbesteko distantzia onena",
        guessDistribution: "Asmatze banaketa:",
      },
      buyMeACoffee: "Erosi iezadazu kafetxo bat ☕!",
    },
  },
  es: {
    translation: {
      placeholder: "Sartu EHko edozein herri",
      guess: "Asmatu",
      share: "Zabaldu",
      showOnGoogleMaps: "👀 OpenStreetMaps-en",
      welldone: "Ondo!",
      unknownCountry: "Herri ezezaguna!",
      copy: "Emaitzak arbelean kopiatuta!",
      showCountry: "🗺️ Erakutsi mapan!",
      cancelRotation: "🌀 Ezeztatu errotazioa",
      settings: {
        title: "Aukerak",
        distanceUnit: "Distantzia unitatea",
        theme: "Gaia",
        difficultyModifiers: "Zailtasun aldagaiak",
        startingNextDay: "Bihar egingo dira aldaketak!",
        noImageMode: "Ezkutatu herriaren irudia",
        rotationMode: "Errotatu ausaz herriaren irudia.",
      },
      stats: {
        title: "Estatistikak",
        played: "Jokatuta",
        win: "Irabaziak %",
        currentStreak: "Uneko seriea",
        maxStreak: "Serie Max",
        averageBestDistance: "Batazbesteko distantzia onena",
        guessDistribution: "Asmatze banaketa:",
      },
      buyMeACoffee: "Erosi iezadazu kafetxo bat ☕!",
    },
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: "eu",
  });

export default i18n;
