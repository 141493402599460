import React from "react";

export function Worldle() {
  return (
    <span className="font-bold">
      WOR<span className="text-green-600">L</span>DL
      <span className="text-red-600">EH</span>
    </span>
  );
}
