// Source:
// Countries with long/lat => https://developers.google.com/public-data/docs/canonical/countries_csv
// Countries images => https://github.com/djaiss/mapsicon

const countryCodesWithImage = [
  "Abadiño",
  "Abaigar",
  "Abaltzisketa",
  "Abanto",
  "Abartzuza",
  "Abaurregaina",
  "Abaurrepea",
  "Aberin",
  "Ablitas",
  "Adios",
  "Aduna",
  "Agoitz",
  "Aguilar Kodes",
  "Agurain",
  "Ahatsa-Altzieta-Bazkazane",
  "Ahetze",
  "Ahurti",
  "Aia",
  "Aiara",
  "Aiegi",
  "Aiherra",
  "Ainharbe",
  "Ainhize-Monjolose",
  "Ainhoa",
  "Aintzila",
  "Aizarnazabal",
  "Aiziritze-Gamue-Zohazti",
  "Ajangiz",
  "Akamarre",
  "Albiztur",
  "Aldude",
  "Alegia",
  "Alesbes",
  "Alkiza",
  "Allin",
  "Allo",
  "Alonsotegi",
  "Aloze-Ziboze-Onizegaine",
  "Altsasu",
  "Altzaga",
  "Altzai-Altzabeheti-Zunharreta",
  "Altzo",
  "Altzürükü",
  "Amendüze-Unaso",
  "Ameskoabarrena",
  "Amezketa",
  "Amoroto",
  "Amorotze-Zokotze",
  "Amurrio",
  "Añana",
  "Andoain",
  "Andosilla",
  "Angelu",
  "Anhauze",
  "Anoeta",
  "Añorbe",
  "Antsoain",
  "Antzin",
  "Antzuola",
  "Anue",
  "Araitz",
  "Arakaldo",
  "Arakil",
  "Arama",
  "Aramaio",
  "Aranaratxe",
  "Aranguren",
  "Arano",
  "Arantza",
  "Arantzazu",
  "Aras",
  "Arberatze-Zilhekoa",
  "Arbizu",
  "Arbona",
  "Arboti-Zohota",
  "Areatza",
  "Arellano",
  "Areso",
  "Aretxabaleta",
  "Argantzon",
  "Arguedas",
  "Arhantsusi",
  "Aria",
  "Aribe",
  "Armañantzas",
  "Armendaritze",
  "Armiñon",
  "Arnegi",
  "Arraia-Maeztu",
  "Arrangoitze",
  "Arrankudiaga",
  "Arrasate",
  "Arratzu",
  "Arratzua-Ubarrundia",
  "Arrieta",
  "Arrigorriaga",
  "Arroitz",
  "Arrokiaga",
  "Arrosa",
  "Arruazu",
  "Arruta-Sarrikota-Amikuze",
  "Artaxoa",
  "Artazu",
  "Arteaga",
  "Artzentales",
  "Artzibar",
  "Artziniega",
  "Arüe-Ithorrotze-Olhaibi",
  "Asparrena",
  "Asteasu",
  "Astigarraga",
  "Atarrabia",
  "Ataun",
  "Atetz",
  "Atharratze-Sorholüze",
  "Atxondo",
  "Aulesti",
  "Auritz",
  "Azagra",
  "Azkaine",
  "Azkarate",
  "Azkoien",
  "Azkoitia",
  "Azpeitia",
  "Azuelo",
  "Baigorri",
  "Baiona",
  "Bakaiku",
  "Bakio",
  "Baliarrain",
  "Balmaseda",
  "Banka",
  "Barakaldo",
  "Barañain",
  "Barasoain",
  "Barbarin",
  "Bardoze",
  "Bargota",
  "Barillas",
  "Barkoxe",
  "Barrika",
  "Barrundia",
  "Basaburua",
  "Basauri",
  "Bastida (Araba)",
  "Bastida (Nafarroa-Beherea)",
  "Basusarri",
  "Baztan",
  "Beasain",
  "Bedia",
  "Behaskane-Laphizketa",
  "Behauze",
  "Behorlegi",
  "Beire",
  "Beizama",
  "Belauntza",
  "Bera",
  "Berango",
  "Berantevilla",
  "Beraskoain",
  "Berastegi",
  "Berbintzana",
  "Bergara",
  "Beriain",
  "Bermeo",
  "Bernedo",
  "Berriatua",
  "Berriobeiti",
  "Berriozar",
  "Berriz",
  "Berrobi",
  "Berrogaine-Larüntze",
  "Bertizarana",
  "Beskoitze",
  "Betelu",
  "Biarritz",
  "Bidania-Goiatz",
  "Bidankoze",
  "Bidarrai",
  "Bidarte",
  "Bidaurreta",
  "Bidaxune",
  "Bilbo",
  "Bildoze-Onizepea",
  "Biriatu",
  "Biurrun-Olkotz",
  "Bokale",
  "Buñuel",
  "Bunuze",
  "Burgelu",
  "Burgi",
  "Burgue-Erreiti",
  "Burlata",
  "Buztintze-Hiriberri",
  "Busto",
  "Busturia",
  "Cabanillas",
  "Cabredo",
  "Cadreita",
  "Caparroso",
  "Cárcar",
  "Cascante",
  "Castejón",
  "Cintruenigo",
  "Corella",
  "Cortes",
  "Deba",
  "Deierri",
  "Deikaztelu",
  "Derio",
  "Desojo",
  "Dima",
  "Domintxaine-Berroeta",
  "Donaixti-Ibarre",
  "Donamaria",
  "Donamartiri",
  "Donapaleu",
  "Donazaharre",
  "Donemiliaga",
  "Doneztebe",
  "Donibane Garazi",
  "Donibane Lohizune",
  "Donostia",
  "Donoztiri",
  "Dulantzi",
  "Durango",
  "Duzunaritze-Sarasketa",
  "Ea",
  "Eguesibar",
  "Eibar",
  "Eiheralarre",
  "Ekora",
  "Elantxobe",
  "Elduain",
  "Elgeta",
  "Elgoibar",
  "Elgorriaga",
  "Elizagorria",
  "Elorrio",
  "Elo",
  "Eltziego",
  "Eneritz",
  "Erandio",
  "Erango",
  "Eratsun",
  "Ereño",
  "Ergoiena",
  "Ermua",
  "Errenteria",
  "Errezil",
  "Erriberabeitia",
  "Erriberagoitia",
  "Erriberri",
  "Errigoiti",
  "Erroibar",
  "Erromantzatua",
  "Erronkari",
  "Esa",
  "Eskiula",
  "Eskoriatza",
  "Eslaba",
  "Espartza Zaraitzu",
  "Esprontzeda",
  "Esteribar",
  "Etaiu",
  "Etxalar",
  "Etxarri Aranatz",
  "Etxarri Etxauribar",
  "Etxarri (Zuberoa)",
  "Etxauri",
  "Etxebarre",
  "Etxebarri (Etxebarri-Doneztebeko elizatea)*",
  "Eulate",
  "Ezkabarte",
  "Ezkaroze",
  "Ezkio",
  "Ezkurra",
  "Ezpeize-Ündüreine",
  "Ezpeleta",
  "Ezporogi",
  "Ezterenzubi",
  "Faltzes",
  "Fitero",
  "Fontellas",
  "Forua",
  "Fruiz",
  "Funes",
  "Fustiñana",
  "Gabadi",
  "Gabiria",
  "Gaintza",
  "Galar",
  "Galdakao",
  "Galdames",
  "Galipentzu",
  "Galoze",
  "Gamarte",
  "Gamere-Zihiga",
  "Gamiz-Fika",
  "Garai",
  "Garaioa",
  "Garde",
  "Gares",
  "Garindaine",
  "Garinoain",
  "Garralda",
  "Garrüze",
  "Gasteiz",
  "Gatika",
  "Gautegiz-Arteaga",
  "Gazteluberri",
  "Gaztelu",
  "Genevilla",
  "Gernika-Lumo",
  "Gesalatz",
  "Getaria (Gipuzkoa)",
  "Getaria (Lapurdi)",
  "Getxo",
  "Girgillao",
  "Gixune",
  "Gizaburuaga",
  "Goizueta",
  "Goñerri",
  "Gordexola",
  "Gorliz",
  "Gorza",
  "Gotaine-Irabarne",
  "Guardia (Biastari)",
  "Gueñes",
  "Haltsu",
  "Harana",
  "Hauze",
  "Hazparne",
  "Heleta",
  "Hendaia",
  "Hernani",
  "Hernialde",
  "Hiriberri Atetz",
  "Hiriburu",
  "Hondarribia",
  "Hozta",
  "Ibargoiti",
  "Ibarrangelu",
  "Ibarra",
  "Idauze-Mendi",
  "Idiazabal",
  "Igantzi",
  "Igorre",
  "Iguzkitza",
  "Iholdi",
  "Ikaztegieta",
  "Ilharre",
  "Imotz",
  "Irisarri",
  "Irulegi",
  "Irun",
  "Iruña Oka",
  "Irunberri",
  "Iruñea",
  "Irura",
  "Iruraiz-Gauna",
  "Iruri",
  "Irurtzun",
  "Ispaster",
  "Itsasondo",
  "Itsaso",
  "Itsasu",
  "Ituren",
  "Iturmendi",
  "Itza",
  "Itzagaondoa",
  "Itzaltzu",
  "Iurreta",
  "Izaba",
  "Izpura",
  "Izturitze",
  "Izura-Azme",
  "Izurtza",
  "Jaitz",
  "Jatsu (Lapurdi)",
  "Jatsu",
  "Jaurrieta",
  "Jeztaze",
  "Jutsi",
  "Kanbo",
  "Kanpezu",
  "Karrantza",
  "Kaseda",
  "Kortezubi",
  "Kripan",
  "Kuartango",
  "Labetze-Bizkai",
  "Lagran",
  "Lakarra",
  "Lakarri-Arhane-Sarrikotagaine",
  "Lakuntza",
  "Lana",
  "Landibarre",
  "Lanestosa",
  "Lantaron",
  "Lantziego",
  "Lantz",
  "Lapoblación",
  "Lapuebla Labarka",
  "Larrabetzu",
  "Larraga",
  "Larragoa",
  "Larraine",
  "Larraul",
  "Larraun",
  "Larresoro",
  "Larribarre-Sorhapürü",
  "Larzabale-Arroze-Zibitze",
  "Lasa",
  "Lasarte-Oria",
  "Laudio",
  "Laukiz",
  "Lazkao",
  "Leaburu",
  "Leatxe",
  "Ledea",
  "Legarda",
  "Legaria",
  "Legazpi",
  "Legorreta",
  "Legutio",
  "Lehuntze",
  "Leintz Gatzaga",
  "Leioa",
  "Leitza",
  "Lekeitio",
  "Lekorne",
  "Lekuine",
  "Lekunberri (Nafarroa)",
  "Lekunberri (Nafarroa Beherea)",
  "Lemoa",
  "Lemoiz",
  "Leotz",
  "Lerga",
  "Lerin",
  "Lesaka",
  "Lexantzü-Zünharre",
  "Leza",
  "Lezama",
  "Lezaun",
  "Lezo",
  "Ligi-Atherei",
  "Liginaga-Astüe",
  "Lizarra",
  "Lizartza",
  "Lizoainibar-Arriasgoiti",
  "Lodosa",
  "Lohitzüne-Oihergi",
  "Loiu",
  "Longida",
  "Luhuso",
  "Lukin",
  "Lüküze-Altzümarta",
  "Luzaide",
  "Makea",
  "Mallabia",
  "Mañaria",
  "Mañeru",
  "Mañueta",
  "Marañón",
  "Markina-Xemein",
  "Martxueta",
  "Martzilla",
  "Maruri-Jatabe",
  "Maule-Lextarre",
  "Mehaine",
  "Mélida",
  "Meñaka",
  "Mendabia",
  "Mendaro",
  "Mendata",
  "Mendaza",
  "Mendexa",
  "Mendibe",
  "Mendigorria",
  "Mendikota",
  "Metauten",
  "Milafranga",
  "Milagro",
  "Mirafuentes",
  "Miranda Arga",
  "Mitikile-Larrori-Mendibile",
  "Monteagudo",
  "Montori",
  "Moreda Araba",
  "Morentin",
  "Morga",
  "Mues",
  "Mugerre",
  "Mundaka",
  "Mungia",
  "Munitibar-Arbatzegi-Gerrikaitz",
  "Murchante",
  "Murieta",
  "Murillo el Cuende",
  "Murillo el Fruto",
  "Murueta",
  "Muruzabal",
  "Muskildi",
  "Muskiz",
  "Mutiloa",
  "Mutriku",
  "Muxika",
  "Nabarniz",
  "Nabaskoze",
  "Navaridas",
  "Nazar",
  "Obanos",
  "Odieta",
  "Oiartzun",
  "Oibar",
  "Oion",
  "Oitz",
  "Okondo",
  "Oko",
  "Olaberria",
  "Olaibar",
  "Olatzagutia",
  "Olexua",
  "Ollo",
  "Oloritz",
  "Oltza",
  "Oñati",
  "Ondarroa",
  "Oragarre",
  "Orbaizeta",
  "Orbara",
  "Ordizia",
  "Orendain",
  "Orexa",
  "Orio",
  "Orisoain",
  "Orkoien",
  "Ormaiztegi",
  "Orontze",
  "Orotz-Betelu",
  "Orozko",
  "Orreaga",
  "Ortuella",
  "Ortzaize",
  "Ospitalepea",
  "Ostankoa",
  "Oteitza",
  "Otsagabia",
  "Otxandio",
  "Ozaraine-Erribareitia",
  "Ozaze-Zühara",
  "Pagola",
  "Pasaia",
  "Petilla Aragoikoa",
  "Piedramillera",
  "Pitillas",
  "Plentzia",
  "Portugalete",
  "Puiu",
  "Ribaforada",
  "Saldias",
  "Samaniego",
  "Samatze",
  "San Adrián",
  "Santakara",
  "Santsol",
  "Santurtzi",
  "Sara",
  "Sarrikotapea",
  "Sartaguda",
  "Sartze",
  "Segura",
  "Senpere",
  "Sesma",
  "Sestao",
  "Sohüta",
  "Sondika",
  "Sopela",
  "Sopuerta",
  "Soraluze",
  "Sorlada",
  "Suhuskune",
  "Sukarrieta",
  "Sunbilla",
  "Tafalla",
  "Tebas-Muru Artederreta",
  "Tirapu",
  "Tolosa",
  "Torralba del Río",
  "Torres del Río",
  "Trapagaran",
  "Trebiñu",
  "Tulebras",
  "Turtzioz",
  "Tutera",
  "Txulapain",
  "Ubide",
  "Ugao",
  "Uharte Arakil",
  "Uharte Eguesibar",
  "Uharte Garazi",
  "Uhartehiri",
  "Ukar",
  "Ultzama",
  "Untzitibar",
  "Untzue",
  "Urdatx - Santa Grazi",
  "Urdazubi",
  "Urdiain",
  "Urdiñarbe",
  "Urduliz",
  "Urduña",
  "Urepele",
  "Urizaharra",
  "Urkabustaiz",
  "Urketa",
  "Urnieta",
  "Urraulbeiti",
  "Urraulgoiti",
  "Urretxu",
  "Urrotz hiribildua",
  "Urrotz",
  "Urruña",
  "Ürrüstoi-Larrabile",
  "Urzainki",
  "Usurbil",
  "Uterga",
  "Uxue",
  "Uztaritze",
  "Uztarroze",
  "Valtierra",
  "Viana",
  "Villabona-Amasa",
  "Villabuena",
  "Villamayor de Monjardín",
  "Villatuerta",
  "Xabier",
  "Zabaltza",
  "Zaldibar",
  "Zaldibia",
  "Zalduondo",
  "Zalgize-Doneztebe",
  "Zalla",
  "Zamudio",
  "Zanbrana",
  "Zangoza",
  "Zaratamo",
  "Zarautz",
  "Zare",
  "Zaro",
  "Zarrakaztelu",
  "Zeanuri",
  "Zeberio",
  "Zegama",
  "Zerain",
  "Zestoa",
  "Ziburu",
  "Zierbena",
  "Zigoitia",
  "Ziordia",
  "Ziortza-Bolibar",
  "Zirauki",
  "Ziritza Etxauribar",
  "Zizurkil",
  "Zizur Nagusia",
  "Zizur",
  "Zornotza",
  "Zubieta",
  "Zugarramurdi",
  "Zuia",
  "Zumaia",
  "Zumarraga",
  "Zuñiga",
  "Zuraide",
];

export interface Country {
  code: string;
  longitude: number;
  latitude: number;
}

export const countries: Country[] = [
  { code: "Abadiño", longitude: -2.6075, latitude: 43.1525 },
  { code: "Abaigar", longitude: -2.183055555, latitude: 42.65 },
  { code: "Abaltzisketa", longitude: -2.1052186, latitude: 43.047842 },
  { code: "Abanto", longitude: -3.071944444, latitude: 43.314722222 },
  { code: "Abartzuza", longitude: -2.0225, latitude: 42.726388888 },
  { code: "Abaurregaina", longitude: -1.195592155, latitude: 42.90139216 },
  { code: "Abaurrepea", longitude: -1.203093567, latitude: 42.90439674 },
  { code: "Aberin", longitude: -2.007222222, latitude: 42.619166666 },
  { code: "Ablitas", longitude: -1.638611111, latitude: 41.973611111 },
  { code: "Adios", longitude: -1.735833333, latitude: 42.686388888 },
  { code: "Aduna", longitude: -2.050099, latitude: 43.2038168 },
  { code: "Agoitz", longitude: -1.353177971, latitude: 42.78277175 },
  { code: "Aguilar Kodes", longitude: -2.389166666, latitude: 42.612777777 },
  { code: "Agurain", longitude: -2.3890554, latitude: 42.8492849 },
  {
    code: "Ahatsa-Altzieta-Bazkazane",
    longitude: -1.165,
    latitude: 43.150555555,
  },
  { code: "Ahetze", longitude: -1.571111111, latitude: 43.405833333 },
  { code: "Ahurti", longitude: -1.290555555, latitude: 43.494166666 },
  { code: "Aia", longitude: -2.1486667, latitude: 43.236547 },
  { code: "Aiara", longitude: -3.078922917, latitude: 43.07656 },
  { code: "Aiegi", longitude: -2.038333333, latitude: 42.656666666 },
  { code: "Aiherra", longitude: -1.254166666, latitude: 43.392222222 },
  { code: "Ainharbe", longitude: -0.930277777, latitude: 43.260555555 },
  {
    code: "Ainhize-Monjolose",
    longitude: -1.155833333,
    latitude: 43.205277777,
  },
  { code: "Ainhoa", longitude: -1.498611111, latitude: 43.306666666 },
  { code: "Aintzila", longitude: -1.195277777, latitude: 43.144722222 },
  { code: "Aizarnazabal", longitude: -2.2362989, latitude: 43.2557918 },
  {
    code: "Aiziritze-Gamue-Zohazti",
    longitude: -1.023888888,
    latitude: 43.336666666,
  },
  { code: "Ajangiz", longitude: -2.669884201, latitude: 43.29982495 },
  { code: "Akamarre", longitude: -1.110555555, latitude: 43.473055555 },
  { code: "Albiztur", longitude: -2.1373229, latitude: 43.1294071 },
  { code: "Aldude", longitude: -1.425833333, latitude: 43.096111111 },
  { code: "Alegia", longitude: -2.0983582, latitude: 43.1004352 },
  { code: "Alesbes", longitude: -1.75, latitude: 42.283055555 },
  { code: "Alkiza", longitude: -2.1089901, latitude: 43.1725845 },
  { code: "Allin", longitude: -2.075555555, latitude: 42.708888888 },
  { code: "Allo", longitude: -2.019166666, latitude: 42.567222222 },
  { code: "Alonsotegi", longitude: -2.987778, latitude: 43.246667 },
  { code: "Aloze", longitude: -0.87638889, latitude: 43.11861111 },
  {
    code: "Aloze-Ziboze-Onizegaine",
    longitude: -0.873888888,
    latitude: 43.114722222,
  },
  { code: "Altsasu", longitude: -2.16516, latitude: 42.89999 },
  { code: "Altzabeheti", longitude: -0.9025, latitude: 43.10055556 },
  { code: "Altzaga", longitude: -2.154722222, latitude: 43.064166666 },
  { code: "Altzai", longitude: -0.9094763, latitude: 43.0951176 },
  {
    code: "Altzai-Altzabeheti-Zunharreta",
    longitude: -0.908888888,
    latitude: 43.095,
  },
  { code: "Altzo", longitude: -2.0842078, latitude: 43.1004025 },
  { code: "Altzürükü", longitude: -0.933611111, latitude: 43.149444444 },
  { code: "Amendüze", longitude: -1.04277778, latitude: 43.35472222 },
  { code: "Amendüze-Unaso", longitude: -1.046666666, latitude: 43.345277777 },
  { code: "Ameskoabarrena", longitude: -2.131691666, latitude: 42.770888888 },
  { code: "Amezketa", longitude: -2.0880713, latitude: 43.0493025 },
  { code: "Amoroto", longitude: -2.5132747, latitude: 43.3267938 },
  { code: "Amorotze", longitude: -1.11083333, latitude: 43.36611111 },
  { code: "Amorotze-Zokotze", longitude: -1.112222222, latitude: 43.365277777 },
  { code: "Amurrio", longitude: -3.000896, latitude: 43.0525066 },
  { code: "Añana", longitude: -2.986388888, latitude: 42.801944444 },
  { code: "Andoain", longitude: -2.019888, latitude: 43.2183341 },
  { code: "Andosilla", longitude: -1.941944444, latitude: 42.376944444 },
  { code: "Angelu", longitude: -1.519444444, latitude: 43.484166666 },
  { code: "Anhauze", longitude: -1.291111111, latitude: 43.169166666 },
  { code: "Anoeta", longitude: -2.07, latitude: 43.162222 },
  { code: "Añorbe", longitude: -1.714551, latitude: 42.658392 },
  { code: "Antsoain", longitude: -1.639444444, latitude: 42.835555555 },
  { code: "Antzin", longitude: -2.189166666, latitude: 42.659444444 },
  { code: "Antzuola", longitude: -2.380624, latitude: 43.0990781 },
  { code: "Anue", longitude: -1.606111111, latitude: 42.9625 },
  { code: "Araitz", longitude: -1.987222222, latitude: 43.036944444 },
  { code: "Arakaldo", longitude: -2.9354354, latitude: 43.1530741 },
  { code: "Arakil", longitude: -1.865, latitude: 42.9125 },
  { code: "Arama", longitude: -2.1655819, latitude: 43.0634673 },
  { code: "Aramaio", longitude: -2.585663195, latitude: 43.03520365 },
  { code: "Aranaratxe", longitude: -2.228333333, latitude: 42.779444444 },
  { code: "Aranguren", longitude: -1.536111111, latitude: 42.789166666 },
  { code: "Arano", longitude: -1.895277777, latitude: 43.199722222 },
  { code: "Arantza", longitude: -1.724722222, latitude: 43.196111111 },
  { code: "Arantzazu", longitude: -2.789166666, latitude: 43.1575 },
  { code: "Aras", longitude: -2.355, latitude: 42.561944444 },
  { code: "Arberatze", longitude: -0.99472222, latitude: 43.3425 },
  {
    code: "Arberatze-Zilhekoa",
    longitude: -0.995833333,
    latitude: 43.341388888,
  },
  { code: "Arbizu", longitude: -2.0394169, latitude: 42.9147356 },
  { code: "Arbona", longitude: -1.551388888, latitude: 43.432222222 },
  { code: "Arboti", longitude: -1.0, latitude: 43.371388888 },
  { code: "Arboti-Zohota", longitude: -1.001388888, latitude: 43.370277777 },
  { code: "Areatza", longitude: -2.7681889, latitude: 43.1215986 },
  { code: "Arellano", longitude: -2.046944444, latitude: 42.606111111 },
  { code: "Areso", longitude: -1.95, latitude: 43.066666666 },
  { code: "Aretxabaleta", longitude: -2.504444, latitude: 43.036111 },
  { code: "Argantzon", longitude: -2.831388888, latitude: 42.766111111 },
  { code: "Arguedas", longitude: -1.598333333, latitude: 42.176944444 },
  { code: "Arhane", longitude: -0.90722222, latitude: 43.08888889 },
  { code: "Arhantsusi", longitude: -1.035555555, latitude: 43.2575 },
  { code: "Aria", longitude: -1.273314291, latitude: 42.96876268 },
  { code: "Aribe", longitude: -1.265804856, latitude: 42.94470386 },
  { code: "Armañantzas", longitude: -2.285, latitude: 42.56 },
  { code: "Armendaritze", longitude: -1.173333333, latitude: 43.301666666 },
  { code: "Armiñon", longitude: -2.8722115, latitude: 42.722587 },
  { code: "Arnegi", longitude: -1.281111111, latitude: 43.109166666 },
  { code: "Arraia-Maeztu", longitude: -2.447996624, latitude: 42.74065855 },
  { code: "Arrangoitze", longitude: -1.520833333, latitude: 43.436666666 },
  { code: "Arrankudiaga", longitude: -2.934927697, latitude: 43.17704915 },
  { code: "Arrasate", longitude: -2.49, latitude: 43.065555555 },
  { code: "Arratzu", longitude: -2.63861, latitude: 43.3117 },
  {
    code: "Arratzua-Ubarrundia",
    longitude: -2.639166666,
    latitude: 42.890277777,
  },
  { code: "Arrieta", longitude: -2.7697002, latitude: 43.3402674 },
  { code: "Arrigorriaga", longitude: -2.886111111, latitude: 43.207777777 },
  { code: "Arroitz", longitude: -2.091308, latitude: 42.5888239 },
  { code: "Arrokiaga", longitude: -0.84, latitude: 43.190833333 },
  { code: "Arrosa", longitude: -1.313333333, latitude: 43.238055555 },
  { code: "Arroze", longitude: -1.07305556, latitude: 43.23416667 },
  { code: "Arruazu", longitude: -1.999, latitude: 42.922 },
  { code: "Arrueta", longitude: -1.10555556, latitude: 43.39972222 },
  {
    code: "Arruta-Sarrikota-Amikuze",
    longitude: -1.106666666,
    latitude: 43.398611111,
  },
  { code: "Artaxoa", longitude: -1.764444444, latitude: 42.591111111 },
  { code: "Artazu", longitude: -1.8401742, latitude: 42.6913086 },
  { code: "Arteaga", longitude: -2.7843203, latitude: 43.1334436 },
  { code: "Artzentales", longitude: -3.228003971, latitude: 43.2405209 },
  { code: "Artzibar", longitude: -1.337955, latitude: 42.889555 },
  { code: "Artziniega", longitude: -3.140961176, latitude: 43.1299385 },
  { code: "Arüe", longitude: -0.91722222, latitude: 43.31111111 },
  {
    code: "Arüe-Ithorrotze-Olhaibi",
    longitude: -0.9175,
    latitude: 43.318055555,
  },
  { code: "Asparrena", longitude: -2.284788861, latitude: 42.8861483 },
  { code: "Asteasu", longitude: -2.0953614, latitude: 43.1928306 },
  { code: "Astigarraga", longitude: -1.9474769, latitude: 43.2815684 },
  { code: "Astüe", longitude: -0.8590641, latitude: 43.0998878 },
  { code: "Atarrabia", longitude: -1.608611111, latitude: 42.830833333 },
  { code: "Ataun", longitude: -2.1812767, latitude: 42.9781042 },
  { code: "Atetz", longitude: -1.709722222, latitude: 42.939444444 },
  { code: "Atharratze", longitude: -0.86361111, latitude: 43.11722222 },
  { code: "Atharratze-Sorholüze", longitude: -0.8625, latitude: 43.116388888 },
  { code: "Atherei", longitude: -0.87444444, latitude: 43.07833333 },
  { code: "Atxondo", longitude: -2.583611111, latitude: 43.13 },
  { code: "Aulesti", longitude: -2.5628139, latitude: 43.296423 },
  { code: "Auritz", longitude: -1.33463753, latitude: 42.990805137 },
  { code: "Azagra", longitude: -1.897222222, latitude: 42.313888888 },
  { code: "Azkaine", longitude: -1.62073, latitude: 43.34521 },
  { code: "Azkaine", longitude: -1.621111111, latitude: 43.345833333 },
  { code: "Azkarate", longitude: -1.255, latitude: 43.170277777 },
  { code: "Azkoien", longitude: -1.8, latitude: 42.333055555 },
  { code: "Azkoitia", longitude: -2.310556, latitude: 43.179167 },
  { code: "Azpeitia", longitude: -2.265277777, latitude: 43.181944444 },
  { code: "Azuelo", longitude: -2.349166666, latitude: 42.608333333 },
  { code: "Baigorri", longitude: -1.346388888, latitude: 43.175 },
  { code: "Baiona", longitude: -1.476388888, latitude: 43.4925 },
  { code: "Bakaiku", longitude: -2.1021121, latitude: 42.8906052 },
  { code: "Bakio", longitude: -2.811389, latitude: 43.427778 },
  { code: "Baliarrain", longitude: -2.1283913, latitude: 43.0694191 },
  { code: "Balmaseda", longitude: -3.192777777, latitude: 43.195833333 },
  { code: "Banka", longitude: -1.374166666, latitude: 43.126388888 },
  { code: "Barakaldo", longitude: -2.991666666, latitude: 43.297222222 },
  { code: "Barañain", longitude: -1.683333333, latitude: 42.8 },
  { code: "Barasoain", longitude: -1.646388888, latitude: 42.603611111 },
  { code: "Barbarin", longitude: -2.101388888, latitude: 42.601944444 },
  { code: "Bardoze", longitude: -1.203611111, latitude: 43.475 },
  { code: "Bargota", longitude: -2.311944444, latitude: 42.561111111 },
  { code: "Barillas", longitude: -1.6627451, latitude: 41.97102 },
  { code: "Barkoxe", longitude: -0.772222222, latitude: 43.188611111 },
  { code: "Barrika", longitude: -2.9609879, latitude: 43.4066267 },
  { code: "Barrundia", longitude: -2.502957468, latitude: 42.91528705 },
  { code: "Basaburua", longitude: -1.801666666, latitude: 43.001388888 },
  { code: "Basauri", longitude: -2.89, latitude: 43.236666666 },
  {
    code: "Bastida (Nafarroa-Beherea)",
    longitude: -1.256111111,
    latitude: 43.43,
  },
  { code: "Bastida (Araba)", longitude: -2.7928698, latitude: 42.5903817 },
  { code: "Basusarri", longitude: -1.511666666, latitude: 43.446666666 },
  { code: "Baztan", longitude: -1.5171681, latitude: 43.146787 },
  { code: "Beasain", longitude: -2.2032868, latitude: 43.0473269 },
  { code: "Bedia", longitude: -2.8019783, latitude: 43.2084398 },
  { code: "Behaskane", longitude: -1.009444444, latitude: 43.325 },
  {
    code: "Behaskane-Laphizketa",
    longitude: -1.010555555,
    latitude: 43.324166666,
  },
  { code: "Behauze", longitude: -1.086666666, latitude: 43.359444444 },
  { code: "Behorlegi", longitude: -1.118611111, latitude: 43.128611111 },
  { code: "Beire", longitude: -1.621691, latitude: 42.4539389 },
  { code: "Beizama", longitude: -2.2002744, latitude: 43.1341562 },
  { code: "Belauntza", longitude: -2.0503391, latitude: 43.1350267 },
  { code: "Bera", longitude: -1.6825, latitude: 43.278333333 },
  { code: "Berango", longitude: -2.995, latitude: 43.365 },
  { code: "Berantevilla", longitude: -2.8589445, latitude: 42.682852 },
  { code: "Beraskoain", longitude: -1.8325, latitude: 42.756111111 },
  { code: "Berastegi", longitude: -1.9791586, latitude: 43.1241497 },
  { code: "Berbintzana", longitude: -1.834166666, latitude: 42.526388888 },
  { code: "Bergara", longitude: -2.4133452, latitude: 43.1175442 },
  { code: "Beriain", longitude: -1.644444444, latitude: 42.733611111 },
  { code: "Bermeo", longitude: -2.721388888, latitude: 43.420833333 },
  { code: "Bernedo", longitude: -2.4980928, latitude: 42.6267187 },
  { code: "Berriatua", longitude: -2.467222222, latitude: 43.309722222 },
  { code: "Berriobeiti", longitude: -1.6925, latitude: 42.8525 },
  { code: "Berriozar", longitude: -1.671388888, latitude: 42.836111111 },
  { code: "Berriz", longitude: -2.575555555, latitude: 43.175833333 },
  { code: "Berrobi", longitude: -2.0266424, latitude: 43.1455807 },
  { code: "Berroeta", longitude: -0.985, latitude: 43.32444444 },
  {
    code: "Berrogaine-Larüntze",
    longitude: -0.864722222,
    latitude: 43.244722222,
  },
  { code: "Bertizarana", longitude: -1.62725, latitude: 43.133638888 },
  { code: "Beskoitze", longitude: -1.333611111, latitude: 43.46 },
  { code: "Betelu", longitude: -1.9794889, latitude: 43.0256703 },
  { code: "Biarritz", longitude: -1.557222222, latitude: 43.480555555 },
  { code: "Bidania-Goiatz", longitude: -2.158333333, latitude: 43.140833333 },
  { code: "Bidankoze", longitude: -1.0143, latitude: 42.8014 },
  { code: "Bidarrai", longitude: -1.345, latitude: 43.266666666 },
  { code: "Bidarte", longitude: -1.593055555, latitude: 43.436944444 },
  { code: "Bidaurreta", longitude: -1.832777777, latitude: 42.776666666 },
  { code: "Bidaxune", longitude: -1.141388888, latitude: 43.483333333 },
  { code: "Bilbo", longitude: -2.953333333, latitude: 43.262222222 },
  { code: "Bildoze", longitude: -0.8813, latitude: 43.24203083 },
  { code: "Bildoze-Onizepea", longitude: -0.881111111, latitude: 43.241111111 },
  { code: "Biriatu", longitude: -1.743055555, latitude: 43.333333333 },
  { code: "Bithiriña", longitude: -1.080833333, latitude: 43.3125 },
  { code: "Biurrun-Olkotz", longitude: -1.679444444, latitude: 42.695 },
  { code: "Bizkai (Amikuze)", longitude: -1.05777778, latitude: 43.40277778 },
  { code: "Bokale", longitude: -1.486666666, latitude: 43.523611111 },
  { code: "Buñuel", longitude: -1.440833333, latitude: 41.981388888 },
  { code: "Bunuze", longitude: -1.067222222, latitude: 43.202777777 },
  { code: "Burgi", longitude: -1.000833333, latitude: 42.721111111 },
  { code: "Burgu", longitude: -2.545677, latitude: 42.8494909 },
  { code: "Burgelu", longitude: -1.05527778, latitude: 43.42388889 },
  { code: "Burgue-Erreiti", longitude: -1.056666666, latitude: 43.422777777 },
  { code: "Burlata", longitude: -1.61563, latitude: 42.82648 },
  { code: "Busturia", longitude: -2.696666666, latitude: 43.382777777 },
  {
    code: "Buztintze-Hiriberri",
    longitude: -1.183611111,
    latitude: 43.183611111,
  },
  { code: "Cabanillas", longitude: -1.526944444, latitude: 42.031388888 },
  { code: "Cabredo", longitude: -2.4145728, latitude: 42.6293014 },
  { code: "Cadreita", longitude: -1.684166666, latitude: 42.221111111 },
  { code: "Caparroso", longitude: -1.6507, latitude: 42.3398 },
  { code: "Cárcar", longitude: -1.97, latitude: 42.388888888 },
  { code: "Cascante", longitude: -1.678888888, latitude: 41.999166666 },
  { code: "Castejón", longitude: -1.690555555, latitude: 42.167777777 },
  { code: "Cintruenigo", longitude: -1.805, latitude: 42.08 },
  { code: "Corella", longitude: -1.786666666, latitude: 42.114722222 },
  { code: "Cortes", longitude: -1.421944444, latitude: 41.922777777 },
  { code: "Deba", longitude: -2.35, latitude: 43.295277777 },
  { code: "Deierri", longitude: -2.0, latitude: 42.733333 },
  { code: "Deikaztelu", longitude: -2.028333333, latitude: 42.595833333 },
  { code: "Derio", longitude: -2.8858821, latitude: 43.2916949 },
  { code: "Desojo", longitude: -2.2757428, latitude: 42.5881304 },
  { code: "Dima", longitude: -2.708382366, latitude: 43.1093279 },
  { code: "Domintxaine", longitude: -0.97583333, latitude: 43.3275 },
  {
    code: "Domintxaine-Berroeta",
    longitude: -0.965277777,
    latitude: 43.326944444,
  },
  { code: "Donaixti", longitude: -1.05527778, latitude: 43.19527778 },
  { code: "Donaixti-Ibarre", longitude: -1.056388888, latitude: 43.194166666 },
  { code: "Donamaria", longitude: -1.672777777, latitude: 43.11125 },
  { code: "Donamartiri", longitude: -1.196944444, latitude: 43.344444444 },
  { code: "Donamartiri Untz", longitude: -1.560277777, latitude: 42.524444444 },
  { code: "Donapaleu", longitude: -1.033888888, latitude: 43.328055555 },
  { code: "Donazaharre", longitude: -1.193333333, latitude: 43.165277777 },
  { code: "Donemiliaga", longitude: -2.376111111, latitude: 42.874722222 },
  { code: "Doneztebe", longitude: -1.66875, latitude: 43.131 },
  { code: "Donibane Garazi", longitude: -1.236666666, latitude: 43.164166666 },
  {
    code: "Donibane Lohizune",
    longitude: -1.659722222,
    latitude: 43.390277777,
  },
  { code: "Donostia", longitude: -1.98, latitude: 43.32 },
  { code: "Donoztiri", longitude: -1.2086, latitude: 43.33792 },
  { code: "Donoztiri", longitude: -1.208611111, latitude: 43.338333333 },
  { code: "Dulantzi", longitude: -2.511944444, latitude: 42.844166666 },
  { code: "Durango", longitude: -2.6321025, latitude: 43.166974 },
  {
    code: "Duzunaritze-Sarasketa",
    longitude: -1.167777777,
    latitude: 43.163055555,
  },
  { code: "Ea", longitude: -2.583611111, latitude: 43.381111111 },
  { code: "Eguesibar", longitude: -1.557361111, latitude: 42.823722222 },
  { code: "Eibar", longitude: -2.4732748, latitude: 43.1842839 },
  { code: "Eiheralarre", longitude: -1.221111111, latitude: 43.136111111 },
  { code: "Ekora", longitude: -2.4704125, latitude: 42.5678 },
  { code: "Busto", longitude: -2.241666666, latitude: 42.548888888 },
  { code: "Elantxobe", longitude: -2.638611111, latitude: 43.403888888 },
  { code: "Elduain", longitude: -2.0006998, latitude: 43.1406884 },
  { code: "Elgeta", longitude: -2.487682, latitude: 43.136637 },
  { code: "Elgoibar", longitude: -2.416944444, latitude: 43.214166666 },
  { code: "Elgorriaga", longitude: -1.686944444, latitude: 43.138888888 },
  { code: "Elizagorria", longitude: -2.238888888, latitude: 42.493611111 },
  { code: "Elo", longitude: -1.522977779, latitude: 42.70606128 },
  { code: "Elorrio", longitude: -2.5426453, latitude: 43.1304644 },
  { code: "Eltziego", longitude: -2.6183241, latitude: 42.5147699 },
  { code: "Elvillar", longitude: -2.545, latitude: 42.570555555 },
  { code: "Eneritz", longitude: -1.716666666, latitude: 42.666666666 },
  { code: "Erandio", longitude: -2.973055555, latitude: 43.304722222 },
  { code: "Erango", longitude: -1.050833333, latitude: 43.443611111 },
  { code: "Eratsun", longitude: -1.796666666, latitude: 43.083333333 },
  { code: "Ereño", longitude: -2.615890656, latitude: 43.35113795 },
  { code: "Ergoiena", longitude: -2.023888888, latitude: 42.878888888 },
  { code: "Ermua", longitude: -2.5025, latitude: 43.186666666 },
  { code: "Erreiti", longitude: -1.06166667, latitude: 43.41527778 },
  { code: "Errenteria", longitude: -1.8986133, latitude: 43.3125271 },
  { code: "Errezil", longitude: -2.174167, latitude: 43.165 },
  { code: "Erribareita", longitude: -0.93861111, latitude: 43.39833333 },
  { code: "Erriberabeitia", longitude: -2.8912758, latitude: 42.7397636 },
  { code: "Erriberagoitia", longitude: -2.904371125, latitude: 42.79819925 },
  { code: "Erriberri", longitude: -1.6625, latitude: 42.4875 },
  { code: "Errigoiti", longitude: -2.723888888, latitude: 43.32 },
  { code: "Erroibar", longitude: -1.428128963, latitude: 42.957137839 },
  { code: "Erromantzatua", longitude: -1.191871579, latitude: 42.69083518 },
  { code: "Erronkari", longitude: -0.955833333, latitude: 42.8075 },
  { code: "Esa", longitude: -1.200448119, latitude: 42.62590878 },
  { code: "Eskiula", longitude: -0.708333333, latitude: 43.194166666 },
  { code: "Eskoriatza", longitude: -2.5283464, latitude: 43.0168367 },
  { code: "Eslaba", longitude: -1.457222222, latitude: 42.565 },
  { code: "Espartza Zaraitzu", longitude: -1.092551686, latitude: 42.86048584 },
  { code: "Esprontzeda", longitude: -2.3025, latitude: 42.598055555 },
  { code: "Esteribar", longitude: -1.533763318, latitude: 42.94962887 },
  { code: "Etaiu", longitude: -2.153888888, latitude: 42.616666666 },
  { code: "Etxalar", longitude: -1.637222222, latitude: 43.233611111 },
  {
    code: "Etxarri (Zuberoa)",
    longitude: -0.928055555,
    latitude: 43.323333333,
  },
  {
    code: "Etxarri Etxauribar",
    longitude: -1.825277777,
    latitude: 42.780555555,
  },
  { code: "Etxarri Aranatz", longitude: -2.07, latitude: 42.911111111 },
  { code: "Etxauri", longitude: -1.79, latitude: 42.793888888 },
  { code: "Etxebarre", longitude: -0.892777777, latitude: 43.082222222 },
  {
    code: "Etxebarri (Etxebarri-Doneztebeko elizatea)*",
    longitude: -2.890833333,
    latitude: 43.247222222,
  },
  { code: "Etxebarria", longitude: -2.477222222, latitude: 43.254166666 },
  { code: "Eulate", longitude: -2.2061503, latitude: 42.7764335 },
  { code: "Ezkabarte", longitude: -1.620833333, latitude: 42.863333333 },
  { code: "Ezkaroze", longitude: -1.066443886, latitude: 42.89668876 },
  { code: "Ezkio", longitude: -2.276172, latitude: 43.088416 },
  { code: "Ezkoze", longitude: -0.997777777, latitude: 43.446944444 },
  { code: "Ezkurra", longitude: -1.862222222, latitude: 43.084722222 },
  { code: "Ezpeize", longitude: -0.87972222, latitude: 43.26805556 },
  {
    code: "Ezpeize-Ündüreine",
    longitude: -0.878888888,
    latitude: 43.271111111,
  },
  { code: "Ezpeleta", longitude: -1.447777777, latitude: 43.340277777 },
  { code: "Ezporogi", longitude: -1.417777777, latitude: 42.566666666 },
  { code: "Ezterenzubi", longitude: -1.195555555, latitude: 43.104444444 },
  { code: "Faltzes", longitude: -1.794722222, latitude: 42.387777777 },
  { code: "Fitero", longitude: -1.857222222, latitude: 42.058055555 },
  { code: "Fontellas", longitude: -1.576111111, latitude: 42.028333333 },
  { code: "Forua", longitude: -2.6754845, latitude: 43.3373452 },
  { code: "Fruiz", longitude: -2.789501095, latitude: 43.3276522 },
  { code: "Funes", longitude: -1.8029166, latitude: 42.3140325 },
  { code: "Fustiñana", longitude: -1.486111111, latitude: 42.019722222 },
  { code: "Gabadi", longitude: -1.034166666, latitude: 43.370833333 },
  { code: "Gabiria", longitude: -2.2791464, latitude: 43.0502538 },
  { code: "Gaintza", longitude: -2.1325, latitude: 43.053056 },
  { code: "Galar", longitude: -1.671583333, latitude: 42.750305555 },
  { code: "Galdakao", longitude: -2.845833333, latitude: 43.230555555 },
  { code: "Galdames", longitude: -3.095639603, latitude: 43.266113 },
  { code: "Galipentzu", longitude: -1.389444444, latitude: 42.530555555 },
  { code: "Galoze", longitude: -1.096584475, latitude: 42.79343706 },
  { code: "Gamarte", longitude: -1.143333333, latitude: 43.201388888 },
  { code: "Gamere", longitude: -0.90638889, latitude: 43.11638889 },
  { code: "Gamere-Zihiga", longitude: -0.906111111, latitude: 43.116111111 },
  { code: "Gamiz-Fika", longitude: -2.825125304, latitude: 43.3140716 },
  { code: "Gamue", longitude: -1.01916667, latitude: 43.36916667 },
  { code: "Gamue-Zohazti", longitude: -1.01916667, latitude: 43.36916667 },
  { code: "Garai", longitude: -2.609722222, latitude: 43.194722222 },
  { code: "Garaioa", longitude: -1.237237509, latitude: 42.91620815 },
  { code: "Garde", longitude: -0.923888888, latitude: 42.788888888 },
  { code: "Gares", longitude: -1.8155291, latitude: 42.6717107 },
  { code: "Garindaine", longitude: -0.905555555, latitude: 43.208611111 },
  { code: "Garinoain", longitude: -1.646369, latitude: 42.603555 },
  { code: "Garralda", longitude: -1.304737695, latitude: 42.95946462 },
  { code: "Garrüze", longitude: -1.061111111, latitude: 43.3425 },
  { code: "Gasteiz", longitude: -2.673055555, latitude: 42.846666666 },
  { code: "Gatika", longitude: -2.871944444, latitude: 43.363888888 },
  { code: "Gaubea", longitude: -3.060860075, latitude: 42.8546615 },
  { code: "Gautegiz-Arteaga", longitude: -2.652224874, latitude: 43.36818995 },
  { code: "Gaztelu", longitude: -2.0228291, latitude: 43.1164447 },
  { code: "Gazteluberri", longitude: -1.044444444, latitude: 42.677777777 },
  { code: "Genevilla", longitude: -2.391666666, latitude: 42.644722222 },
  { code: "Gernika-Lumo", longitude: -2.676666666, latitude: 43.316944444 },
  { code: "Gesalatz", longitude: -1.916666666, latitude: 42.75 },
  {
    code: "Getaria (Lapurdi)",
    longitude: -1.609166666,
    latitude: 43.425833333,
  },
  { code: "Getaria (Gipuzkoa)", longitude: -2.2037374, latitude: 43.3044559 },
  { code: "Getxo", longitude: -3.006388888, latitude: 43.344166666 },
  { code: "Girgillao", longitude: -1.867765642, latitude: 42.7222897 },
  { code: "Gixune", longitude: -1.204166666, latitude: 43.511666666 },
  { code: "Gizaburuaga", longitude: -2.5379481, latitude: 43.3309436 },
  { code: "Goizueta", longitude: -1.864166666, latitude: 43.171111111 },
  { code: "Goñerri", longitude: -1.866666666, latitude: 42.833333333 },
  { code: "Gordexola", longitude: -3.072777777, latitude: 43.181388888 },
  { code: "Gorliz", longitude: -2.932777777, latitude: 43.416111111 },
  { code: "Gorza", longitude: -1.066468067, latitude: 42.82864858 },
  { code: "Gotaine", longitude: -0.9020332, latitude: 43.1905628 },
  { code: "Gotaine-Irabarne", longitude: -0.901666666, latitude: 43.190833333 },
  { code: "Guardia (Biastari)", longitude: -2.583055555, latitude: 42.55 },
  { code: "Gueñes", longitude: -3.094166666, latitude: 43.213888888 },
  { code: "Haltsu", longitude: -1.4225, latitude: 43.374444444 },
  { code: "Harana", longitude: -2.320570256, latitude: 42.7565931 },
  { code: "Hauze", longitude: -0.848611111, latitude: 43.077777777 },
  { code: "Hazparne", longitude: -1.304722222, latitude: 43.384444444 },
  { code: "Heleta", longitude: -1.244166666, latitude: 43.308333333 },
  { code: "Hendaia", longitude: -1.774444444, latitude: 43.358611111 },
  { code: "Hernani", longitude: -1.9749167, latitude: 43.2661645 },
  { code: "Hernialde", longitude: -2.085194, latitude: 43.1544948 },
  { code: "Hiriberri Atetz", longitude: -1.210797313, latitude: 42.95398484 },
  { code: "Hiriburu", longitude: -1.458888888, latitude: 43.476388888 },
  { code: "Hondarribia", longitude: -1.7914777, latitude: 43.3624372 },
  { code: "Hozta", longitude: -1.087777777, latitude: 43.158333333 },
  { code: "Ibargoiti", longitude: -1.460526976, latitude: 42.67546163 },
  { code: "Ibarra", longitude: -2.061666666, latitude: 43.130833333 },
  { code: "Ibarrangelu", longitude: -2.6311482, latitude: 43.3896126 },
  { code: "Ibarre", longitude: -1.07888889, latitude: 43.18083333 },
  { code: "Ibarrola", longitude: -1.095, latitude: 43.200277777 },
  { code: "Idauze", longitude: -0.915, latitude: 43.18055556 },
  { code: "Idauze-Mendi", longitude: -0.916111111, latitude: 43.175 },
  { code: "Idiazabal", longitude: -2.2336128, latitude: 43.0106787 },
  { code: "Igantzi", longitude: -1.699166666, latitude: 43.225555555 },
  { code: "Igorre", longitude: -2.7783435, latitude: 43.1642445 },
  { code: "Iguzkitza", longitude: -2.085277777, latitude: 42.646388888 },
  { code: "Iholdi", longitude: -1.181111111, latitude: 43.281944444 },
  { code: "Ikaztegieta", longitude: -2.125278, latitude: 43.094722 },
  { code: "Ilharre", longitude: -1.042222222, latitude: 43.39 },
  { code: "Imotz", longitude: -1.801645479, latitude: 42.9560159 },
  { code: "Irabarne", longitude: -0.90166667, latitude: 43.19888889 },
  { code: "Irañeta", longitude: -1.94524722, latitude: 42.92292675 },
  { code: "Irisarri", longitude: -1.233611111, latitude: 43.256944444 },
  { code: "Irulegi", longitude: -1.300277777, latitude: 43.177777777 },
  { code: "Irun", longitude: -1.788806, latitude: 43.337806 },
  { code: "Iruña Oka", longitude: -2.812777777, latitude: 42.819166666 },
  { code: "Irunberri", longitude: -1.295129104, latitude: 42.65077159 },
  { code: "Iruñea", longitude: -1.65, latitude: 42.816666666 },
  { code: "Irura", longitude: -2.0672224, latitude: 43.1675342 },
  { code: "Iruraiz-Gauna", longitude: -2.5, latitude: 42.816666666 },
  { code: "Iruri", longitude: -0.876666666, latitude: 43.133333333 },
  { code: "Irurtzun", longitude: -1.8285436, latitude: 42.9183371 },
  { code: "Ispaster", longitude: -2.543056, latitude: 43.362778 },
  { code: "Ithorrotze", longitude: -0.91666667, latitude: 43.3175 },
  { code: "Ithorrotze-Olhaibi", longitude: -0.91666667, latitude: 43.3175 },
  { code: "Itsasondo", longitude: -2.1672356, latitude: 43.0678266 },
  { code: "Itsaso", longitude: -2.2551, latitude: 43.07774 },
  { code: "Itsasu", longitude: -1.405555555, latitude: 43.329444444 },
  { code: "Ituren", longitude: -1.707222222, latitude: 43.128944444 },
  { code: "Iturmendi", longitude: -2.118611111, latitude: 42.889444444 },
  { code: "Itza", longitude: -1.7675, latitude: 42.8825 },
  { code: "Itzagaondoa", longitude: -1.433850086, latitude: 42.72913886 },
  { code: "Itzaltzu", longitude: -1.040213398, latitude: 42.9275427 },
  { code: "Iurreta", longitude: -2.631666666, latitude: 43.177777777 },
  { code: "Izaba", longitude: -0.916666666, latitude: 42.85 },
  { code: "Izpura", longitude: -1.234166666, latitude: 43.170833333 },
  { code: "Izturitze", longitude: -1.205, latitude: 43.365833333 },
  { code: "Izura-Azme", longitude: -1.070833333, latitude: 43.255833333 },
  { code: "Izurtza", longitude: -2.638888888, latitude: 43.155 },
  { code: "Jaitz", longitude: -1.888055555, latitude: 42.775277777 },
  { code: "Jatsu", longitude: -1.192222222, latitude: 43.197777777 },
  { code: "Jatsu (Lapurdi)", longitude: -1.430277777, latitude: 43.388611111 },
  { code: "Jaurrieta", longitude: -1.158691809, latitude: 42.90699476 },
  { code: "Jeztaze", longitude: -0.8825, latitude: 43.350833333 },
  { code: "Jutsi", longitude: -1.0458, latitude: 43.23615 },
  { code: "Jutsi", longitude: -1.046111111, latitude: 43.235833333 },
  { code: "Kanbo", longitude: -1.401666666, latitude: 43.357777777 },
  { code: "Kanpezu", longitude: -2.369947595, latitude: 42.68678985 },
  { code: "Karrantza", longitude: -3.363257383, latitude: 43.2057282 },
  { code: "Kaseda", longitude: -1.367694444, latitude: 42.524027777 },
  { code: "Kortezubi", longitude: -2.655104, latitude: 43.3407404 },
  { code: "Kripan", longitude: -2.516111111, latitude: 42.591666666 },
  { code: "Kuartango", longitude: -2.927502214, latitude: 42.88623625 },
  { code: "Labaien", longitude: -1.738888888, latitude: 43.087222222 },
  { code: "Labetze-Bizkai", longitude: -1.0575, latitude: 43.384166666 },
  { code: "Lagran", longitude: -2.584444444, latitude: 42.625555555 },
  { code: "Lakarra", longitude: -1.164166666, latitude: 43.190277777 },
  {
    code: "Lakarri-Arhane-Sarrikotagaine",
    longitude: -0.924444444,
    latitude: 43.085,
  },
  { code: "Lakuntza", longitude: -2.021388888, latitude: 42.923055555 },
  { code: "Lana", longitude: -2.246944444, latitude: 42.706111111 },
  { code: "Landibarre", longitude: -1.124444444, latitude: 43.25 },
  { code: "Lanestosa", longitude: -3.4372558, latitude: 43.215611 },
  { code: "Lantaron", longitude: -2.994677543, latitude: 42.7468622 },
  { code: "Lantz", longitude: -1.619444444, latitude: 42.998888888 },
  { code: "Lantziego", longitude: -2.5126326, latitude: 42.5627681 },
  { code: "Laphizketa", longitude: -1.0032954, latitude: 43.3223079 },
  { code: "Lapoblación", longitude: -2.474166666, latitude: 42.604722222 },
  { code: "Lapuebla Labarka", longitude: -2.571111111, latitude: 42.495833333 },
  { code: "Larrabetzu", longitude: -2.7959846, latitude: 43.2609468 },
  { code: "Larrabile", longitude: -0.86305556, latitude: 43.27583333 },
  { code: "Larraga", longitude: -1.848611111, latitude: 42.563055555 },
  { code: "Larragoa", longitude: -2.256111111, latitude: 42.780555555 },
  { code: "Larraine", longitude: -0.955833333, latitude: 43.018611111 },
  { code: "Larraul", longitude: -2.1023955, latitude: 43.1879341 },
  { code: "Larraun", longitude: -1.896388888, latitude: 43.005555555 },
  { code: "Larresoro", longitude: -1.439166666, latitude: 43.370277777 },
  {
    code: "Larribarre-Sorhapürü",
    longitude: -1.014166666,
    latitude: 43.292777777,
  },
  {
    code: "Larzabale-Arroze-Zibitze",
    longitude: -1.095833333,
    latitude: 43.232777777,
  },
  { code: "Lasa", longitude: -1.26, latitude: 43.157222222 },
  { code: "Lasarte-Oria", longitude: -2.02, latitude: 43.267777777 },
  { code: "Laudio", longitude: -2.956111111, latitude: 43.151111111 },
  { code: "Laukiz", longitude: -2.914438189, latitude: 43.34971205 },
  { code: "Lazkao", longitude: -2.1875, latitude: 43.034722222 },
  { code: "Leaburu", longitude: -2.05, latitude: 43.116666666 },
  { code: "Leatxe", longitude: -1.406944444, latitude: 42.607777777 },
  { code: "Ledea", longitude: -1.26485964, latitude: 42.61316359 },
  { code: "Legarda", longitude: -1.770277777, latitude: 42.712222222 },
  { code: "Legaria", longitude: -2.173333333, latitude: 42.648611111 },
  { code: "Legazpi", longitude: -2.335, latitude: 43.055 },
  { code: "Legorreta", longitude: -2.1486222, latitude: 43.0848925 },
  { code: "Legutio", longitude: -2.6467053, latitude: 42.9809869 },
  { code: "Lehuntze", longitude: -1.391111111, latitude: 43.4825 },
  { code: "Leintz Gatzaga", longitude: -2.5687408, latitude: 42.9868906 },
  { code: "Leioa", longitude: -2.984722222, latitude: 43.328888888 },
  { code: "Leitza", longitude: -1.914722222, latitude: 43.078888888 },
  { code: "Lekeitio", longitude: -2.504883333, latitude: 43.363877777 },
  { code: "Lekorne", longitude: -1.298333333, latitude: 43.340277777 },
  { code: "Lekuine", longitude: -1.266666666, latitude: 43.368611111 },
  {
    code: "Lekunberri (Nafarroa)",
    longitude: -1.893888888,
    latitude: 43.003333333,
  },
  {
    code: "Lekunberri (Nafarroa Beherea)",
    longitude: -1.143055555,
    latitude: 43.135,
  },
  { code: "Lemoa", longitude: -2.7788097, latitude: 43.2098775 },
  { code: "Lemoiz", longitude: -2.9022672, latitude: 43.411411 },
  { code: "Leotz", longitude: -1.519166666, latitude: 42.645 },
  { code: "Lerga", longitude: -1.500833333, latitude: 42.566388888 },
  { code: "Lerin", longitude: -1.9737376, latitude: 42.4814412 },
  { code: "Lesaka", longitude: -1.703888888, latitude: 43.249166666 },
  {
    code: "Lexantzü-Zünharre",
    longitude: -0.873333333,
    latitude: 43.092777777,
  },
  { code: "Lextarre", longitude: -0.8925, latitude: 43.22152778 },
  { code: "Leza", longitude: -2.633611111, latitude: 42.566111111 },
  { code: "Lezama", longitude: -2.833333333, latitude: 43.274722222 },
  { code: "Lezaun", longitude: -1.994166666, latitude: 42.780277777 },
  { code: "Lezo", longitude: -1.898888888, latitude: 43.321111111 },
  { code: "Ligi", longitude: -0.87916667, latitude: 43.06666667 },
  { code: "Ligi-Atherei", longitude: -0.876666666, latitude: 43.066944444 },
  { code: "Liginaga", longitude: -0.865555555, latitude: 43.093333333 },
  { code: "Liginaga-Astüe", longitude: -0.865555555, latitude: 43.093333333 },
  { code: "Lizarra", longitude: -2.03064, latitude: 42.6705 },
  { code: "Lizartza", longitude: -2.034166666, latitude: 43.103611111 },
  {
    code: "Lizoainibar-Arriasgoiti",
    longitude: -1.469023688,
    latitude: 42.81979395,
  },
  { code: "Lodosa", longitude: -2.078333333, latitude: 42.424166666 },
  {
    code: "Lohitzüne-Oihergi",
    longitude: -0.977222222,
    latitude: 43.276388888,
  },
  { code: "Loiu", longitude: -2.9388878, latitude: 43.3147645 },
  { code: "Longida", longitude: -1.3623, latitude: 42.776 },
  { code: "Los Arcos", longitude: -2.183055555, latitude: 42.566666666 },
  { code: "Luhuso", longitude: -1.353611111, latitude: 43.316111111 },
  { code: "Lukin", longitude: -2.099166666, latitude: 42.611666666 },
  {
    code: "Lüküze-Altzümarta",
    longitude: -1.076388888,
    latitude: 43.345555555,
  },
  { code: "Luzaide", longitude: -1.320767641, latitude: 43.068365313 },
  { code: "Makea", longitude: -1.3275, latitude: 43.33 },
  { code: "Mallabia", longitude: -2.528888888, latitude: 43.189722222 },
  { code: "Mañaria", longitude: -2.659722222, latitude: 43.139166666 },
  { code: "Mañeru", longitude: -1.8620743, latitude: 42.6695386 },
  { code: "Mañueta", longitude: -2.679444444, latitude: 42.53 },
  { code: "Marañón", longitude: -2.4415614, latitude: 42.6285133 },
  { code: "Markina-Xemein", longitude: -2.496388888, latitude: 43.268888888 },
  { code: "Martxueta", longitude: -1.093888888, latitude: 43.389722222 },
  { code: "Martzilla", longitude: -1.7375, latitude: 42.327777777 },
  { code: "Maruri-Jatabe", longitude: -2.86877087, latitude: 43.39639525 },
  { code: "Maule", longitude: -0.88916667, latitude: 43.22388889 },
  { code: "Maule-Lextarre", longitude: -0.886944444, latitude: 43.224166666 },
  { code: "Mehaine", longitude: -1.145, latitude: 43.333055555 },
  { code: "Mélida", longitude: -1.545277777, latitude: 42.360277777 },
  { code: "Meñaka", longitude: -2.801666666, latitude: 43.364722222 },
  { code: "Mendabia", longitude: -2.200421, latitude: 42.4443286 },
  { code: "Mendaro", longitude: -2.383333333, latitude: 43.25 },
  { code: "Mendata", longitude: -2.6425, latitude: 43.282222222 },
  { code: "Mendaza", longitude: -2.234722222, latitude: 42.642777777 },
  { code: "Mendexa", longitude: -2.4857921, latitude: 43.345833 },
  { code: "Mendi", longitude: -0.915, latitude: 43.16861111 },
  { code: "Mendibe", longitude: -1.134722222, latitude: 43.126111111 },
  { code: "Mendigorria", longitude: -1.834166666, latitude: 42.629444444 },
  { code: "Mendikota", longitude: -0.901666666, latitude: 43.161944444 },
  { code: "Metauten", longitude: -2.125277777, latitude: 42.678611111 },
  { code: "Milafranga", longitude: -1.452777777, latitude: 43.437222222 },
  { code: "Milagro", longitude: -1.7627813, latitude: 42.2417429 },
  { code: "Mirafuentes", longitude: -2.276666666, latitude: 42.623055555 },
  { code: "Miranda Arga", longitude: -1.8266758, latitude: 42.4829883 },
  {
    code: "Mitikile-Larrori-Mendibile",
    longitude: -0.848055555,
    latitude: 43.265833333,
  },
  { code: "Monteagudo", longitude: -1.690277777, latitude: 41.963888888 },
  { code: "Montori", longitude: -0.817777777, latitude: 43.096944444 },
  { code: "Moreda Araba", longitude: -2.408055555, latitude: 42.525 },
  { code: "Morentin", longitude: -2.0125, latitude: 42.614166666 },
  { code: "Morga", longitude: -2.75, latitude: 43.283333 },
  { code: "Mues", longitude: -2.226388888, latitude: 42.606111111 },
  { code: "Mugerre", longitude: -1.416111111, latitude: 43.468333333 },
  { code: "Mundaka", longitude: -2.698333333, latitude: 43.407222222 },
  { code: "Mungia", longitude: -2.847222222, latitude: 43.354722222 },
  {
    code: "Munitibar-Arbatzegi-Gerrikaitz",
    longitude: -2.594750803,
    latitude: 43.2564101,
  },
  { code: "Murchante", longitude: -1.655833333, latitude: 42.03 },
  { code: "Murieta", longitude: -2.156388888, latitude: 42.659444444 },
  {
    code: "Murillo el Cuende",
    longitude: -1.629722222,
    latitude: 42.397777777,
  },
  { code: "Murillo el Fruto", longitude: -1.460833333, latitude: 42.392777777 },
  { code: "Murueta", longitude: -2.680555555, latitude: 43.352777777 },
  { code: "Muruzabal", longitude: -1.780555555, latitude: 42.689444444 },
  { code: "Muskildi", longitude: -0.966111111, latitude: 43.196666666 },
  { code: "Muskiz", longitude: -3.121666666, latitude: 43.323333333 },
  { code: "Mutiloa", longitude: -2.2727507, latitude: 43.0220391 },
  { code: "Mutriku", longitude: -2.385, latitude: 43.307222 },
  { code: "Muxika", longitude: -2.67836, latitude: 43.24872 },
  { code: "Nabarniz", longitude: -2.583611111, latitude: 43.320833333 },
  { code: "Nabaskoze", longitude: -1.116388888, latitude: 42.718333333 },
  { code: "Navaridas", longitude: -2.623333333, latitude: 42.546111111 },
  { code: "Nazar", longitude: -2.278888888, latitude: 42.6375 },
  { code: "Noain Elortzibar", longitude: -1.633333333, latitude: 42.754722222 },
  { code: "Obanos", longitude: -1.785555555, latitude: 42.679444444 },
  { code: "Odieta", longitude: -1.638055555, latitude: 42.939722222 },
  { code: "Oiartzun", longitude: -1.857777777, latitude: 43.299166666 },
  { code: "Oibar", longitude: -1.359444444, latitude: 42.589444444 },
  { code: "Oion", longitude: -2.4364407, latitude: 42.5059034 },
  { code: "Oitz", longitude: -1.685555555, latitude: 43.110555555 },
  { code: "Oko", longitude: -2.163611111, latitude: 42.639722222 },
  { code: "Okondo", longitude: -3.018888888, latitude: 43.162222222 },
  { code: "Olaberria", longitude: -2.2036619, latitude: 43.0269276 },
  { code: "Olaibar", longitude: -1.602777777, latitude: 42.885833333 },
  { code: "Olatzagutia", longitude: -2.194444444, latitude: 42.877777777 },
  { code: "Olexua", longitude: -2.140277777, latitude: 42.624166666 },
  { code: "Olhaibi", longitude: -0.94472222, latitude: 43.31388889 },
  { code: "Ollo", longitude: -1.85, latitude: 42.866666666 },
  { code: "Oloritz", longitude: -1.613055555, latitude: 42.635277777 },
  { code: "Oltza", longitude: -1.753888888, latitude: 42.8225 },
  { code: "Oñati", longitude: -2.411667, latitude: 43.032778 },
  { code: "Ondarroa", longitude: -2.419444444, latitude: 43.321944444 },
  { code: "Onizegaine", longitude: -0.86446111, latitude: 43.11098611 },
  { code: "Onizepea", longitude: -0.880624, latitude: 43.25841667 },
  { code: "Oragarre", longitude: -1.135, latitude: 43.394166666 },
  { code: "Orbaizeta", longitude: -1.221779655, latitude: 43.00289434 },
  { code: "Orbara", longitude: -1.24965336, latitude: 42.97517613 },
  { code: "Ordizia", longitude: -2.178333333, latitude: 43.054722222 },
  { code: "Orendain", longitude: -2.1141376, latitude: 43.0794987 },
  { code: "Orexa", longitude: -2.0113193, latitude: 43.0942358 },
  { code: "Orio", longitude: -2.127262, latitude: 43.2760488 },
  { code: "Orisoain", longitude: -1.603888888, latitude: 42.601111111 },
  { code: "Orkoien", longitude: -1.699166666, latitude: 42.823888888 },
  { code: "Ormaiztegi", longitude: -2.254814, latitude: 43.0431256 },
  { code: "Orontze", longitude: -1.067706525, latitude: 42.87560461 },
  { code: "Orotz-Betelu", longitude: -1.301694856, latitude: 42.906390516 },
  { code: "Orozko", longitude: -2.911111, latitude: 43.108611 },
  { code: "Orreaga", longitude: -1.30468681, latitude: 43.01376679 },
  { code: "Ortuella", longitude: -3.056944444, latitude: 43.310277777 },
  { code: "Ortzaize", longitude: -1.284722222, latitude: 43.241666666 },
  { code: "Ospitalepea", longitude: -0.768611111, latitude: 43.251388888 },
  { code: "Ostankoa", longitude: -1.065277777, latitude: 43.293055555 },
  { code: "Oteitza", longitude: -1.953333333, latitude: 42.618611111 },
  { code: "Otsagabia", longitude: -1.070496434, latitude: 42.960092 },
  { code: "Otxandio", longitude: -2.653333333, latitude: 43.041111111 },
  {
    code: "Ozaraine-Erribareitia",
    longitude: -0.951388888,
    latitude: 43.377222222,
  },
  { code: "Ozaze-Zühara", longitude: -0.890833333, latitude: 43.140277777 },
  { code: "Pagola", longitude: -0.990277777, latitude: 43.224166666 },
  { code: "Pasaia", longitude: -1.919166666, latitude: 43.326388888 },
  {
    code: "Petilla Aragoikoa",
    longitude: -1.092777777,
    latitude: 42.461388888,
  },
  { code: "Piedramillera", longitude: -2.202777777, latitude: 42.632777777 },
  { code: "Pitillas", longitude: -1.622222222, latitude: 42.421944444 },
  { code: "Plentzia", longitude: -2.946388888, latitude: 43.405833333 },
  { code: "Portugalete", longitude: -3.019583, latitude: 43.319444 },
  { code: "Puiu", longitude: -1.648055555, latitude: 42.566111111 },
  { code: "Ribaforada", longitude: -1.510833333, latitude: 41.997222222 },
  { code: "Saldias", longitude: -1.780833333, latitude: 43.088333333 },
  { code: "Samaniego", longitude: -2.6790583, latitude: 42.5682299 },
  { code: "Samatze", longitude: -1.156388888, latitude: 43.524166666 },
  { code: "San Adrián", longitude: -1.933333333, latitude: 42.3325 },
  { code: "Santakara", longitude: -1.5477761, latitude: 42.3754863 },
  { code: "Santizpirita", longitude: -1.46722222, latitude: 43.49555556 },
  { code: "Santsol", longitude: -2.264166666, latitude: 42.554722222 },
  { code: "Santurtzi", longitude: -3.031388888, latitude: 43.330277777 },
  { code: "Sara", longitude: -1.580277777, latitude: 43.312777777 },
  { code: "Sarrikota", longitude: -1.09527778, latitude: 43.41333333 },
  { code: "Sarrikotagaine", longitude: -0.91611111, latitude: 43.09027778 },
  { code: "Sarrikotapea", longitude: -0.881944444, latitude: 43.293888888 },
  { code: "Sartaguda", longitude: -2.058611111, latitude: 42.381388888 },
  { code: "Sartze", longitude: -1.097120163, latitude: 42.84546298 },
  { code: "Segura", longitude: -2.2527923, latitude: 43.009086 },
  { code: "Senpere", longitude: -1.550555555, latitude: 43.356666666 },
  { code: "Serres", longitude: -1.63944444, latitude: 43.36527778 },
  { code: "Sesma", longitude: -2.083333333, latitude: 42.477777777 },
  { code: "Sestao", longitude: -3.005555555, latitude: 43.310833333 },
  { code: "Sohüta", longitude: -0.868333333, latitude: 43.231388888 },
  { code: "Sondika", longitude: -2.9260509, latitude: 43.2990664 },
  { code: "Sopela", longitude: -2.982222222, latitude: 43.381388888 },
  { code: "Sopuerta", longitude: -3.1525, latitude: 43.262777777 },
  { code: "Soraluze", longitude: -2.411722, latitude: 43.174778 },
  { code: "Sorholüze", longitude: -0.86666667, latitude: 43.12055556 },
  { code: "Sorlada", longitude: -2.215277777, latitude: 42.615 },
  { code: "Suhuskune", longitude: -1.198055555, latitude: 43.234722222 },
  { code: "Sukarrieta", longitude: -2.695, latitude: 43.396111111 },
  { code: "Sunbilla", longitude: -1.668333333, latitude: 43.164444444 },
  { code: "Tafalla", longitude: -1.673611111, latitude: 42.528888888 },
  {
    code: "Tebas-Muru Artederreta",
    longitude: -1.640277777,
    latitude: 42.693055555,
  },
  { code: "Tirapu", longitude: -1.701388888, latitude: 42.658611111 },
  { code: "Tolosa", longitude: -2.0723558, latitude: 43.1386097 },
  { code: "Torralba del Río", longitude: -2.329722222, latitude: 42.608611111 },
  { code: "Torres del Río", longitude: -2.269444444, latitude: 42.552777777 },
  { code: "Trapagaran", longitude: -3.035555555, latitude: 43.306111111 },
  { code: "Trebiñu", longitude: -2.747222222, latitude: 42.734722222 },
  { code: "Tulebras", longitude: -1.676388888, latitude: 41.976666666 },
  { code: "Turtzioz", longitude: -3.280335785, latitude: 43.2838127 },
  { code: "Tutera", longitude: -1.606666666, latitude: 42.065277777 },
  { code: "Txulapain", longitude: -1.683333333, latitude: 42.883333333 },
  { code: "Ubide", longitude: -2.6888828, latitude: 43.0241516 },
  { code: "Ugao", longitude: -2.900277777, latitude: 43.181388888 },
  {
    code: "Uharte Eguesibar",
    longitude: -1.590833333,
    latitude: 42.831388888,
  },
  { code: "Uharte Arakil", longitude: -1.96906303, latitude: 42.92093712 },
  { code: "Uharte Garazi", longitude: -1.244444444, latitude: 43.164722222 },
  { code: "Uhartehiri", longitude: -1.021944444, latitude: 43.279166666 },
  { code: "Ukar", longitude: -1.705277777, latitude: 42.676666666 },
  { code: "Ultzama", longitude: -1.678055555, latitude: 43.0 },
  { code: "Unaso", longitude: -1.04666667, latitude: 43.34222222 },
  { code: "Ündüreine", longitude: -0.87722222, latitude: 43.28222222 },
  { code: "Untzitibar", longitude: -1.466986268, latitude: 42.72447304 },
  { code: "Untzue", longitude: -1.626111111, latitude: 42.651944444 },
  {
    code: "Urdatx - Santa Grazi",
    longitude: -0.854166666,
    latitude: 43.014166666,
  },
  { code: "Urdazubi", longitude: -1.503888888, latitude: 43.266388888 },
  { code: "Urdiain", longitude: -2.133055555, latitude: 42.883055555 },
  { code: "Urdiñarbe", longitude: -0.943888888, latitude: 43.185833333 },
  { code: "Urduliz", longitude: -2.9492348, latitude: 43.372649 },
  { code: "Urduña", longitude: -3.008333333, latitude: 42.995833333 },
  { code: "Urepele", longitude: -1.416388888, latitude: 43.069722222 },
  { code: "Urizaharra", longitude: -2.7135349, latitude: 42.6442584 },
  { code: "Urkabustaiz", longitude: -2.903333333, latitude: 42.954722222 },
  { code: "Urketa", longitude: -1.336666666, latitude: 43.485555555 },
  { code: "Urnieta", longitude: -1.9917045, latitude: 43.24687 },
  { code: "Urraul", longitude: -1.261356433, latitude: 42.73429701 },
  { code: "Urraulbeiti", longitude: -1.320394088, latitude: 42.68630388 },
  { code: "Urraulgoiti", longitude: -1.220763763, latitude: 42.78315812 },
  { code: "Urretxu", longitude: -2.313888888, latitude: 43.091666666 },
  { code: "Urrotz", longitude: -1.45268646, latitude: 42.77618492 },
  {
    code: "Urrotz hiribildua",
    longitude: -1.706111111,
    latitude: 43.101388888,
  },
  { code: "Urruña", longitude: -1.7, latitude: 43.362222222 },
  { code: "Ürrüstoi-Larrabile", longitude: -0.85, latitude: 43.293333333 },
  { code: "Urzainki", longitude: -0.944444444, latitude: 42.832777777 },
  { code: "Usurbil", longitude: -2.05, latitude: 43.266666666 },
  { code: "Uterga", longitude: -1.759444444, latitude: 42.71 },
  { code: "Utziate", longitude: -1.1214371, latitude: 43.2190687 },
  { code: "Uxue", longitude: -1.499722222, latitude: 42.506666666 },
  { code: "Uztaritze", longitude: -1.456388888, latitude: 43.399444444 },
  { code: "Uztarroze", longitude: -0.939166666, latitude: 42.892777777 },
  { code: "Valtierra", longitude: -1.634166666, latitude: 42.195277777 },
  { code: "Viana", longitude: -2.3717803, latitude: 42.5151135 },
  { code: "Villabona-Amasa", longitude: -2.0525, latitude: 43.188055555 },
  { code: "Villabuena", longitude: -2.665437, latitude: 42.5470187 },
  { code: "Villamayor de Monjardín", longitude: -2.1, latitude: 42.633055555 },
  { code: "Villatuerta", longitude: -1.9934011, latitude: 42.6595159 },
  { code: "Xabier", longitude: -1.2086914, latitude: 42.5911918 },
  { code: "Zabaltza", longitude: -1.8063225, latitude: 42.7676314 },
  { code: "Zaldibar", longitude: -2.545277777, latitude: 43.1725 },
  { code: "Zaldibia", longitude: -2.149722, latitude: 43.036389 },
  { code: "Zalduondo", longitude: -2.3470952, latitude: 42.8858496 },
  {
    code: "Zalgize-Doneztebe",
    longitude: -0.888055555,
    latitude: 43.151944444,
  },
  { code: "Zalla", longitude: -3.1310266, latitude: 43.2140298 },
  { code: "Zamudio", longitude: -2.8623645, latitude: 43.2827605 },
  { code: "Zanbrana", longitude: -2.8789365, latitude: 42.6616842 },
  { code: "Zangoza", longitude: -1.2825, latitude: 42.577777777 },
  { code: "Zaratamo", longitude: -2.872222222, latitude: 43.212777777 },
  { code: "Zarautz", longitude: -2.1748013, latitude: 43.2863159 },
  { code: "Zare", longitude: -1.4, latitude: 42.583055555 },
  { code: "Zaro", longitude: -1.2175, latitude: 43.1475 },
  { code: "Zarrakaztelu", longitude: -1.444444444, latitude: 42.38 },
  { code: "Zeanuri", longitude: -2.7494389, latitude: 43.0988891 },
  { code: "Zeberio", longitude: -2.8524223, latitude: 43.1468424 },
  { code: "Zegama", longitude: -2.29036, latitude: 42.9758091 },
  { code: "Zerain", longitude: -2.2733725, latitude: 43.0125385 },
  { code: "Zestoa", longitude: -2.2585525, latitude: 43.2401063 },
  { code: "Zibitze", longitude: -1.08888889, latitude: 43.22555556 },
  { code: "Ziboze", longitude: -0.875, latitude: 43.10833333 },
  { code: "Ziburu", longitude: -1.667777777, latitude: 43.385277777 },
  { code: "Zierbena", longitude: -3.082039, latitude: 43.3518319 },
  { code: "Zigoitia", longitude: -2.728151159, latitude: 42.9683205 },
  { code: "Zihiga", longitude: -0.90722222, latitude: 43.12166667 },
  { code: "Zilhekoa", longitude: -0.98694444, latitude: 43.33972222 },
  { code: "Ziordia", longitude: -2.2269974, latitude: 42.8706248 },
  { code: "Ziortza-Bolibar", longitude: -2.555833333, latitude: 43.245 },
  { code: "Zirauki", longitude: -1.888611111, latitude: 42.675833333 },
  {
    code: "Ziritza Etxauribar",
    longitude: -1.826666666,
    latitude: 42.790555555,
  },
  { code: "Zizur Nagusia", longitude: -1.690833333, latitude: 42.786666666 },
  { code: "Zizur", longitude: -1.717783333, latitude: 42.791858333 },
  { code: "Zizurkil", longitude: -2.077222, latitude: 43.2 },
  { code: "Zohazti", longitude: -1.01277778, latitude: 43.35416667 },
  { code: "Zohota", longitude: -0.98638889, latitude: 43.35861111 },
  { code: "Zokotze", longitude: -1.1072, latitude: 43.3773 },
  { code: "Zornotza", longitude: -2.734166666, latitude: 43.219166666 },
  { code: "Zubieta", longitude: -1.742361111, latitude: 43.124888888 },
  { code: "Zugarramurdi", longitude: -1.5416354, latitude: 43.2693436 },
  { code: "Zuia", longitude: -2.832541281, latitude: 42.98189485 },
  { code: "Zumaia", longitude: -2.25, latitude: 43.283333 },
  { code: "Zumarraga", longitude: -2.316666666, latitude: 43.083055555 },
  { code: "Zunharre", longitude: -0.87388889, latitude: 43.09305556 },
  { code: "Zunharreta", longitude: -0.89305556, latitude: 43.10361111 },
  { code: "Zuñiga", longitude: -2.3004117, latitude: 42.6930374 },
  { code: "Zuraide", longitude: -1.474722222, latitude: 43.341944444 },
];

export const countriesWithImage = countries.filter((c) =>
  countryCodesWithImage.includes(c.code)
);

// Source: https://fr.wikipedia.org/wiki/ISO_3166
const frenchCountryNames: Record<string, string> = {
  AF: "Afghanistan",
  AX: "Åland",
  AL: "Albanie",
  DZ: "Algérie",
  AS: "Samoa américaines",
  AD: "Andorre",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctique",
  AG: "Antigua-et-Barbuda",
  AR: "Argentine",
  AM: "Arménie",
  AW: "Aruba",
  AU: "Australie",
  AT: "Autriche",
  AZ: "Azerbaïdjan",
  BS: "Bahamas",
  BH: "Bahreïn",
  BD: "Bangladesh",
  BB: "Barbade",
  BY: "Bélarus",
  BE: "Belgique",
  BZ: "Belize",
  BJ: "Bénin",
  BM: "Bermudes",
  BT: "Bhoutan",
  BO: "Bolivie",
  BQ: "Bonaire, Saint-Eustache et Saba",
  BA: "Bosnie-Herzégovine",
  BW: "Botswana",
  BV: "Bouvet",
  BR: "Brésil",
  IO: "Indien",
  BN: "Brunéi Darussalam",
  BG: "Bulgarie",
  BF: "Burkina Faso",
  BI: "Burundi",
  CV: "Cabo Verde",
  KH: "Cambodge",
  CM: "Cameroun",
  CA: "Canada",
  KY: "Caïmans",
  CF: "République centrafricaine",
  TD: "Tchad",
  CL: "Chili",
  CN: "Chine",
  CX: "Christmas",
  CC: "Cocos",
  CO: "Colombie",
  KM: "Comores",
  CD: "République démocratique du Congo",
  CG: "Congo",
  CK: "Cook",
  CR: "Costa Rica",
  HR: "Croatie",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Chypre",
  CZ: "Tchéquie",
  DK: "Danemark",
  DJ: "Djibouti",
  DM: "Dominique",
  DO: "République dominicaine",
  EC: "Équateur",
  EG: "Égypte",
  SV: "Salvador",
  GQ: "Guinée équatoriale",
  ER: "Érythrée",
  EE: "Estonie",
  SZ: "Eswatini",
  ET: "Éthiopie",
  FK: "Îles Falkland (Malvinas)",
  FO: "Féroé",
  FJ: "Fidji",
  FI: "Finlande",
  FR: "France",
  GF: "Guyane française",
  PF: "Polynésie française",
  TF: "Terres australes françaises",
  GA: "Gabon",
  GM: "Gambie",
  GE: "Géorgie",
  DE: "Allemagne",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Grèce",
  GL: "Groenland",
  GD: "Grenade",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernesey",
  GN: "Guinée",
  GW: "Guinée-Bissau",
  GY: "Guyana",
  HT: "Haïti",
  HM: "Heard-et-Îles MacDonald",
  VA: "Saint-Siège",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hongrie",
  IS: "Islande",
  IN: "Inde",
  ID: "Indonésie",
  IR: "Iran",
  IQ: "Iraq",
  IE: "Irlande",
  IM: "Île de Man",
  IL: "Israël",
  IT: "Italie",
  CI: "Côte d'Ivoire",
  JM: "Jamaïque",
  JP: "Japon",
  JE: "Jersey",
  JO: "Jordanie",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "Corée du Nord",
  KR: "Corée du Sud",
  KW: "Koweït",
  KG: "Kirghizistan",
  LA: "Lao",
  LV: "Lettonie",
  LB: "Liban",
  LS: "Lesotho",
  LR: "Libéria",
  LY: "Libye",
  LI: "Liechtenstein",
  LT: "Lituanie",
  LU: "Luxembourg",
  MO: "Macao",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaisie",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malte",
  MH: "Marshall",
  MQ: "Martinique",
  MR: "Mauritanie",
  MU: "Maurice",
  YT: "Mayotte",
  MX: "Mexique",
  FM: "Micronésie",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolie",
  ME: "Monténégro",
  MS: "Montserrat",
  MA: "Maroc",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibie",
  NR: "Nauru",
  NP: "Népal",
  NL: "Pays-Bas",
  NC: "Nouvelle-Calédonie",
  NZ: "Nouvelle-Zélande",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigéria",
  NU: "Niue",
  NF: "Norfolk",
  MK: "Macédoine du Nord",
  MP: "Mariannes du Nord",
  NO: "Norvège",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palaos",
  PS: "Palestine",
  PA: "Panama",
  PG: "Papouasie-Nouvelle-Guinée",
  PY: "Paraguay",
  PE: "Pérou",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Pologne",
  PT: "Portugal",
  PR: "Porto Rico",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Roumanie",
  RU: "Russie",
  RW: "Rwanda",
  BL: "Saint-Barthélemy",
  SH: "Sainte-Hélène, Ascension et Tristan da Cunha",
  KN: "Saint-Kitts-et-Nevis",
  LC: "Sainte-Lucie",
  MF: "Saint-Martin",
  PM: "Saint-Pierre-et-Miquelon",
  VC: "Saint-Vincent-et-les Grenadines",
  WS: "Samoa",
  SM: "Saint-Marin",
  ST: "Sao Tomé-et-Principe",
  SA: "Arabie saoudite",
  SN: "Sénégal",
  RS: "Serbie",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapour",
  SX: "Saint-Martin",
  SK: "Slovaquie",
  SI: "Slovénie",
  SB: "Salomon",
  SO: "Somalie",
  ZA: "Afrique du Sud",
  GS: "Géorgie du Sud-et-les Îles Sandwich du Sud",
  SS: "Soudan du Sud",
  ES: "Espagne",
  LK: "Sri Lanka",
  SD: "Soudan",
  SR: "Suriname",
  SJ: "Svalbard et l'Île Jan Mayen",
  SE: "Suède",
  CH: "Suisse",
  SY: "Syrie",
  TW: "Taïwan",
  TJ: "Tadjikistan",
  TZ: "Tanzanie",
  TH: "Thaïlande",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinité-et-Tobago",
  TN: "Tunisie",
  TR: "Turquie",
  TM: "Turkménistan",
  TC: "Turks-et-Caïcos",
  TV: "Tuvalu",
  UG: "Ouganda",
  UA: "Ukraine",
  AE: "Émirats arabes unis",
  GB: "Royaume-Uni",
  UM: "Îles mineures éloignées des États-Unis",
  US: "États-Unis d'Amérique",
  UY: "Uruguay",
  UZ: "Ouzbékistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Viet Nam",
  VG: "Vierges britanniques",
  VI: "Vierges des États-Unis",
  WF: "Wallis-et-Futuna",
  EH: "Sahara occidental",
  YE: "Yémen",
  ZM: "Zambie",
  ZW: "Zimbabwe",
  AN: "Antilles néerlandaises",
  GZ: "Bande de Gaza",
  XK: "Kosovo",
};

export function getCountryName(language: string, country: Country) {
  if (language === "fr") {
    return frenchCountryNames[country.code];
  }
  return country.code;
}

export function sanitizeCountryName(countryName: string): string {
  return countryName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[- '()]/g, "")
    .toLowerCase();
}
